@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes rotate-center {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg); } }

.spinner-dot {
  width: 10%;
  position: relative;
  top: 50%;
  left: 50%;
  min-width: 80px;
  max-width: 130px;
  -webkit-animation: rotate-center 2s linear infinite both;
  animation: rotate-center 2s linear infinite both; }
