.game-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh; }
  .game-container .center-banner {
    position: absolute;
    display: none;
    top: calc(100vh / 2 - 175px);
    left: calc(50% - 150px);
    width: 300px;
    height: 250px;
    overflow: hidden; }
    .game-container .center-banner.visible {
      display: inline-block; }
    @media (min-width: 768px) {
      .game-container .center-banner {
        top: calc(100vh / 2 - 125px); } }
  .game-container .footer-banner-hp,
  .game-container .footer-banner-gameplay,
  .game-container .footer-banner-pause,
  .game-container .footer-banner-restart {
    position: absolute;
    bottom: 0;
    left: calc(50% - 160px);
    display: none; }
    .game-container .footer-banner-hp.visible,
    .game-container .footer-banner-gameplay.visible,
    .game-container .footer-banner-pause.visible,
    .game-container .footer-banner-restart.visible {
      display: inline-block; }
  .game-container #snakecolorbrake-com_preroll {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
