.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 500;
  background: #FFFFFF; }
  .loading-overlay .loading-percentage {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #7508FF; }
  .loading-overlay .spinner {
    position: relative;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    animation: loadingI 2s linear infinite; }
    .loading-overlay .spinner .bubble-1,
    .loading-overlay .spinner .bubble-2 {
      position: absolute;
      top: 0;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      background-color: #7508FF;
      animation: bounce 2s ease-in-out infinite; }
    .loading-overlay .spinner .bubble-2 {
      top: auto;
      bottom: 0;
      animation-delay: -1s; }

@keyframes loadingI {
  100% {
    transform: rotate(360deg); } }

@keyframes bounce {
  0%,
  100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }
