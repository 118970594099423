.hero.wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center; }
  @media (min-width: 576px) {
    .hero.wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: normal; } }
  @media (min-width: 1024px) {
    .hero.wrapper {
      padding-right: 40px; } }
  @media (min-width: 1440px) {
    .hero.wrapper {
      padding-right: 0; } }
  .hero.wrapper .hero-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 24px;
    box-shadow: 0px -4px 36px rgba(117, 8, 255, 0.26); }
    @media (min-width: 414px) {
      .hero.wrapper .hero-message {
        align-items: center; } }
    @media (min-width: 576px) {
      .hero.wrapper .hero-message {
        align-items: normal;
        max-width: 305px;
        margin-left: 40px;
        padding: 0;
        box-shadow: none; } }
    @media (min-width: 962px) {
      .hero.wrapper .hero-message {
        max-width: 425px; } }
    @media (min-width: 1440px) {
      .hero.wrapper .hero-message {
        max-width: 435px;
        margin-left: 0; } }
    .hero.wrapper .hero-message .logo {
      height: 100%; }
      @media (min-width: 414px) {
        .hero.wrapper .hero-message .logo {
          width: 90%; } }
      @media (min-width: 576px) {
        .hero.wrapper .hero-message .logo {
          width: 100%;
          height: auto; } }
    .hero.wrapper .hero-message h1 {
      max-width: 480px;
      padding: 20px 0;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      text-align: center;
      line-height: 24px; }
      @media (min-width: 414px) {
        .hero.wrapper .hero-message h1 {
          padding: 24px 0; } }
      @media (min-width: 576px) {
        .hero.wrapper .hero-message h1 {
          padding: 40px 0;
          text-align: left; } }
    .hero.wrapper .hero-message .button {
      width: 100%; }
      @media (min-width: 962px) {
        .hero.wrapper .hero-message .button {
          width: fit-content;
          padding-left: 135px;
          padding-right: 135px; } }
  .hero.wrapper .phone-screens img {
    width: 245px;
    height: 277px; }
    @media (min-width: 414px) {
      .hero.wrapper .phone-screens img {
        width: 315px;
        height: 443px; } }
    @media (min-width: 576px) {
      .hero.wrapper .phone-screens img {
        width: 181px;
        height: 701px; } }
    @media (min-width: 768px) {
      .hero.wrapper .phone-screens img {
        width: 373px;
        height: 690px; } }
    @media (min-width: 1024px) {
      .hero.wrapper .phone-screens img {
        width: 477px;
        height: 720px; } }
    @media (min-width: 1320px) {
      .hero.wrapper .phone-screens img {
        width: 750px;
        height: 982px; } }
