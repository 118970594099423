.modal-adblock_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.75);
  transition: opacity 200ms; }

.modal-adblock_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 485px;
  padding: 55px 30px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 125%;
  background: #37000D;
  box-shadow: 0 14px 26px rgba(55, 0, 13, 0.3); }
  @media (min-width: 414px) {
    .modal-adblock_content {
      padding: 70px 45px;
      font-size: 26px;
      line-height: 140%; } }

.modal-adblock_title {
  margin-bottom: 32px;
  color: #FE5711;
  font-size: 42px;
  font-weight: 500;
  line-height: 120%;
  text-shadow: 1px 1px #FFFFFF; }
  @media (min-width: 414px) {
    .modal-adblock_title {
      margin-bottom: 50px;
      font-size: 44px; } }

.modal-adblock_body {
  max-width: 200px;
  margin-bottom: 45px;
  color: #FFFFFF; }
  @media (min-width: 414px) {
    .modal-adblock_body {
      max-width: 360px; } }
  .modal-adblock_body .yellow {
    color: #FFD700; }

.modal-adblock_button {
  width: 240px;
  padding: 10px 0;
  background: #FFFFFF;
  border-radius: 13px;
  color: #04723A;
  font-weight: 700;
  cursor: pointer; }
  @media (min-width: 414px) {
    .modal-adblock_button {
      width: 300px; } }
  .modal-adblock_button:hover {
    background: #c5fadf;
    text-shadow: 1px 1px #1dd174; }
