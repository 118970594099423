.footer {
  background-color: #7508FF; }
  .footer .wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    padding: 80px 0;
    color: #FFFFFF; }
    @media (max-width: 1440px) {
      .footer .wrapper {
        padding-left: 40px;
        padding-right: 40px; } }
    @media (max-width: 576px) {
      .footer .wrapper {
        flex-flow: column;
        padding-left: 24px;
        padding-right: 24px; } }
    .footer .wrapper .about-us {
      max-width: 430px;
      text-align: left; }
      @media (max-width: 1440px) {
        .footer .wrapper .about-us {
          max-width: 285px; } }
      @media (max-width: 962px) {
        .footer .wrapper .about-us {
          flex-grow: 1;
          max-width: none;
          margin-bottom: 60px; } }
      @media (max-width: 576px) {
        .footer .wrapper .about-us {
          margin-bottom: 48px; } }
      .footer .wrapper .about-us h5 {
        margin-bottom: 32px; }
      .footer .wrapper .about-us .fun {
        color: #FFD323; }
    .footer .wrapper .logo {
      display: flex;
      align-items: center; }
      @media (max-width: 1440px) {
        .footer .wrapper .logo {
          margin-top: 10px; } }
      @media (max-width: 576px) {
        .footer .wrapper .logo {
          align-self: center;
          margin-top: 0;
          margin-bottom: 48px; } }
    .footer .wrapper .terms-use {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .footer .wrapper .terms-use .apply {
        margin-bottom: 18px;
        text-transform: uppercase; }
        @media (max-width: 962px) {
          .footer .wrapper .terms-use .apply {
            margin-bottom: 14px; } }
        @media (max-width: 320px) {
          .footer .wrapper .terms-use .apply {
            margin-bottom: 8px; } }
      .footer .wrapper .terms-use .links > span {
        cursor: pointer;
        text-decoration: underline; }
  .footer .copyright {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #C393FF;
    background-color: #4A00A8; }
    @media (max-width: 320px) {
      .footer .copyright p {
        max-width: 210px; } }
