.modal-push_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.75);
  transition: opacity 200ms; }

.modal-push_content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 485px;
  padding: 70px 45px;
  color: #4A00A8;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 140%;
  background: #E0C7FF;
  border-radius: 20px; }

.modal-push_title {
  margin: 0 20px 28px; }

.modal-push_button-allow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  padding: 21px 38px;
  border-radius: 10px;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #4A00A8;
  cursor: pointer; }
  .modal-push_button-allow:hover {
    background-color: #3A0083;
    box-shadow: -12px 12px 22px rgba(58, 0, 131, 0.16), 12px 12px 22px rgba(58, 0, 131, 0.16); }

.modal-push_button-emoji {
  margin-left: 18px;
  margin-bottom: 3px; }

.modal-push_button-continue {
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer; }
  .modal-push_button-continue:hover {
    text-decoration: none; }
