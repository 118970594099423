@media (min-width: 1024px) {
  .container.small {
    padding-left: 16px;
    padding-right: 16px; } }

@media (min-width: 1920px) {
  .container.small {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 1024px) {
  .features.wrapper {
    max-width: 1410px; } }

@media (min-width: 1024px) {
  .features.wrapper .title {
    padding-left: 24px; } }

@media (min-width: 1920px) {
  .features.wrapper .title {
    padding-left: 30px; } }

.features.wrapper .feature-items {
  display: flex;
  flex-direction: column; }
  @media (min-width: 1024px) {
    .features.wrapper .feature-items {
      flex-direction: row; } }
  .features.wrapper .feature-items .features-wrapper {
    display: flex; }
    @media (min-width: 1024px) {
      .features.wrapper .feature-items .features-wrapper {
        flex-basis: 50%; } }
    .features.wrapper .feature-items .features-wrapper .feature-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 50%;
      margin-top: 32px; }
      @media (min-width: 576px) {
        .features.wrapper .feature-items .features-wrapper .feature-item {
          margin-top: 40px; } }
      @media (min-width: 768px) {
        .features.wrapper .feature-items .features-wrapper .feature-item {
          margin-top: 68px; } }
      @media (min-width: 1024px) {
        .features.wrapper .feature-items .features-wrapper .feature-item {
          margin-top: 40px; } }
      .features.wrapper .feature-items .features-wrapper .feature-item .feature-img {
        margin-bottom: 20px; }
        @media (min-width: 414px) {
          .features.wrapper .feature-items .features-wrapper .feature-item .feature-img {
            margin-bottom: 24px; } }
        @media (min-width: 576px) {
          .features.wrapper .feature-items .features-wrapper .feature-item .feature-img {
            margin-bottom: 32px; } }
        @media (min-width: 768px) {
          .features.wrapper .feature-items .features-wrapper .feature-item .feature-img {
            margin-bottom: 40px; } }
      @media (min-width: 768px) {
        .features.wrapper .feature-items .features-wrapper .feature-item .feature-title {
          max-width: 300px; } }
      @media (min-width: 1024px) {
        .features.wrapper .feature-items .features-wrapper .feature-item .feature-title {
          max-width: 210px; } }
      @media (min-width: 1920px) {
        .features.wrapper .feature-items .features-wrapper .feature-item .feature-title {
          max-width: 250px; } }
