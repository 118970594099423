.about.wrapper {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .about.wrapper {
      flex-direction: row;
      justify-content: space-between; } }
  @media (min-width: 1440px) {
    .about.wrapper {
      justify-content: initial; } }
  .about.wrapper .phone {
    align-self: center;
    margin-bottom: 24px; }
    @media (min-width: 414px) {
      .about.wrapper .phone {
        margin-bottom: 32px; } }
    @media (min-width: 576px) {
      .about.wrapper .phone {
        margin-bottom: 48px; } }
    @media (min-width: 768px) {
      .about.wrapper .phone {
        margin-bottom: 0; } }
    @media (min-width: 1440px) {
      .about.wrapper .phone {
        margin-right: 250px; } }
    .about.wrapper .phone img {
      max-width: none;
      width: 235px;
      height: 476px; }
      @media (min-width: 414px) {
        .about.wrapper .phone img {
          width: 329px;
          height: 662px; } }
      @media (min-width: 1024px) {
        .about.wrapper .phone img {
          width: 348px;
          height: 702px; } }
  .about.wrapper .about-info {
    text-align: left; }
    @media (min-width: 768px) {
      .about.wrapper .about-info {
        align-self: center;
        max-width: 330px; } }
    @media (min-width: 962px) {
      .about.wrapper .about-info {
        max-width: 460px; } }
    @media (min-width: 1440px) {
      .about.wrapper .about-info {
        max-width: 590px; } }
    .about.wrapper .about-info .description {
      padding: 40px 0; }
    @media (min-width: 962px) {
      .about.wrapper .about-info .button {
        max-width: 320px; } }
