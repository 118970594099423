.container.advantages {
  padding: 32px 4px; }
  @media (min-width: 768px) {
    .container.advantages {
      padding: 120px 4px; } }
  @media (min-width: 962px) {
    .container.advantages {
      padding: 120px 20px; } }

@media (min-width: 1440px) {
  .advantages.wrapper {
    max-width: 1390px; } }

.advantages.wrapper .title {
  margin-bottom: 32px;
  margin-left: 20px; }
  @media (min-width: 576px) {
    .advantages.wrapper .title {
      margin-bottom: 40px;
      margin-left: 0;
      text-align: center; } }
  @media (min-width: 768px) {
    .advantages.wrapper .title {
      margin-left: 20px;
      text-align: left; } }

.advantages.wrapper .slider-container div:nth-child(4) {
  justify-content: center !important;
  padding-right: 150px; }
  @media (min-width: 768px) {
    .advantages.wrapper .slider-container div:nth-child(4) {
      padding-right: 130px; } }

.advantages.wrapper .slider-container div:nth-child(5) .pagination-dots {
  fill: #7508FF !important;
  padding: 1px 6px !important; }

.advantages.wrapper .slider-container div:nth-child(5) .slider-control-bottomcenter > ul {
  top: -8px !important; }

.advantages.wrapper .slider-container div:nth-child(6) {
  justify-content: center !important;
  padding-left: 150px; }
  @media (min-width: 768px) {
    .advantages.wrapper .slider-container div:nth-child(6) {
      padding-left: 135px; } }

.advantages.wrapper .my-carousel {
  padding-bottom: 60px; }
  .advantages.wrapper .my-carousel .card_img > img {
    width: 100%;
    height: auto; }
  .advantages.wrapper .my-carousel .card_content {
    min-height: 200px;
    padding: 30px 24px;
    background-color: #FFFFFF;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px; }
    @media (min-width: 414px) {
      .advantages.wrapper .my-carousel .card_content {
        min-height: 190px;
        padding: 30px 30px; } }
    @media (min-width: 576px) {
      .advantages.wrapper .my-carousel .card_content {
        min-height: 210px;
        padding: 30px 40px; } }
    @media (min-width: 768px) {
      .advantages.wrapper .my-carousel .card_content {
        min-height: 215px;
        padding: 34px 30px; } }
    @media (min-width: 1024px) {
      .advantages.wrapper .my-carousel .card_content {
        padding: 40px 40px; } }
  .advantages.wrapper .my-carousel .card_title {
    margin-bottom: 16px; }
    @media (min-width: 414px) {
      .advantages.wrapper .my-carousel .card_title {
        margin-bottom: 24px; } }
    @media (min-width: 1024px) {
      .advantages.wrapper .my-carousel .card_title {
        margin-bottom: 32px; } }

.next-btn,
.prev-btn {
  border: none;
  background-color: transparent;
  cursor: pointer; }
