.modal-con {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  z-index: 1000; }
  @media (orientation: landscape) {
    .modal-con .modal-dialog {
      width: 95vw;
      max-height: 80vh;
      margin: 10vh auto;
      max-width: 950px; }
      .modal-con .modal-dialog .modal-content {
        display: flex;
        flex-direction: column;
        width: 95%;
        max-width: 950px;
        height: 80vh;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 0; }
        .modal-con .modal-dialog .modal-content img {
          width: unset; }
        .modal-con .modal-dialog .modal-content ::-webkit-scrollbar {
          width: 10px; }
        .modal-con .modal-dialog .modal-content ::-webkit-scrollbar-track {
          background: #f1f1f1; }
        .modal-con .modal-dialog .modal-content ::-webkit-scrollbar-thumb {
          background-color: #373d45;
          border-radius: 10px;
          border: 2px solid #555555; }
        .modal-con .modal-dialog .modal-content .modal-header {
          display: flex;
          justify-content: space-between;
          padding: 15px 20px 15px 55px;
          border-bottom-color: #bfbfbf; }
          .modal-con .modal-dialog .modal-content .modal-header .modal-title {
            margin: 0;
            padding: 0;
            font-family: "Open-Sans", sans-serif;
            font-size: 2em;
            font-weight: 700;
            color: #373d45; }
          .modal-con .modal-dialog .modal-content .modal-header .close {
            position: relative;
            top: -10%;
            height: fit-content;
            padding: 0;
            font-size: 2em;
            font-weight: 900;
            border: none;
            background-color: transparent;
            opacity: 1;
            outline: none;
            cursor: pointer; }
        .modal-con .modal-dialog .modal-content .modal-body {
          width: 100%;
          height: 80%;
          padding: 5px;
          font-family: "Open-Sans", sans-serif;
          font-weight: 400;
          font-size: 16px;
          color: #373d45;
          border-top: 1px solid #bfbfbf;
          border-bottom: 1px solid #bfbfbf;
          box-sizing: border-box;
          line-height: initial;
          overflow-y: scroll;
          overflow-x: hidden; } }
      @media (orientation: landscape) and (orientation: portrait) and (min-width: 375px) {
        .modal-con .modal-dialog .modal-content .modal-body {
          height: 78%; } }
      @media (orientation: landscape) and (orientation: portrait) and (min-width: 410px) {
        .modal-con .modal-dialog .modal-content .modal-body {
          height: 80%; } }
      @media (orientation: landscape) and (orientation: portrait) and (min-width: 600px) {
        .modal-con .modal-dialog .modal-content .modal-body {
          height: 85%; } }
      @media (orientation: landscape) and (orientation: portrait) and (min-width: 1020px) {
        .modal-con .modal-dialog .modal-content .modal-body {
          height: 90%;
          padding: 5px 55px; } }
      @media (orientation: landscape) and (orientation: landscape) and (min-width: 950px) {
        .modal-con .modal-dialog .modal-content .modal-body {
          padding: 5px 55px; } }
      @media (orientation: landscape) and (orientation: landscape) and (min-width: 1020px) {
        .modal-con .modal-dialog .modal-content .modal-body {
          height: 100%; } }
  @media (orientation: landscape) {
          .modal-con .modal-dialog .modal-content .modal-body ul {
            padding-left: 20px; }
          .modal-con .modal-dialog .modal-content .modal-body a {
            cursor: pointer;
            color: #007bff;
            text-decoration: underline; }
          .modal-con .modal-dialog .modal-content .modal-body .one_third_privacy {
            width: 30.3%;
            margin: 0 3%;
            display: inline-block;
            vertical-align: top; }
          .modal-con .modal-dialog .modal-content .modal-body .two_third {
            width: 60.6%;
            display: inline-block; }
          .modal-con .modal-dialog .modal-content .modal-body.no-title h1 {
            display: none; }
          .modal-con .modal-dialog .modal-content .modal-body.no-img p > img {
            display: none; }
        .modal-con .modal-dialog .modal-content .modal-footer {
          display: flex;
          padding: 0.5em;
          border-top-color: #bfbfbf; }
          .modal-con .modal-dialog .modal-content .modal-footer .modal-btn {
            border-radius: 50px;
            border: none;
            margin: auto;
            font-family: "Open-Sans", sans-serif;
            width: 125px;
            font-size: 16px;
            background-color: #253138;
            color: #fff;
            line-height: 40px;
            cursor: pointer; } }
  @media (orientation: portrait) {
    .modal-con .modal-dialog {
      max-width: 95vw;
      max-height: 80vh;
      margin: 10vh auto;
      font-size: 1em; }
      .modal-con .modal-dialog .modal-content {
        border-radius: 0;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        background-color: #fff;
        max-width: 950px;
        width: 95%;
        height: 80vh;
        bottom: 10%;
        padding: 0; }
        .modal-con .modal-dialog .modal-content .modal-body {
          overflow-y: scroll;
          overflow-x: hidden;
          font-family: "Open-Sans", sans-serif;
          font-weight: 400;
          color: #373d45;
          border-top: 1px solid #bfbfbf;
          border-bottom: 1px solid #bfbfbf;
          box-sizing: border-box;
          line-height: initial;
          padding: 5px 5px;
          width: 100%;
          height: 80%;
          margin-bottom: 10px;
          font-size: 16px; }
          .modal-con .modal-dialog .modal-content .modal-body ul {
            padding-left: 20px; }
          .modal-con .modal-dialog .modal-content .modal-body a {
            cursor: pointer;
            color: #007bff;
            text-decoration: underline; }
          .modal-con .modal-dialog .modal-content .modal-body .one_third_privacy {
            width: 30.3%;
            margin: 0 3%;
            display: inline-block;
            vertical-align: top; }
          .modal-con .modal-dialog .modal-content .modal-body .two_third {
            width: 60.6%;
            display: inline-block; }
          .modal-con .modal-dialog .modal-content .modal-body.no-title h1 {
            display: none; }
          .modal-con .modal-dialog .modal-content .modal-body.no-img p > img {
            display: none; }
        .modal-con .modal-dialog .modal-content ::-webkit-scrollbar {
          width: 10px; }
        .modal-con .modal-dialog .modal-content ::-webkit-scrollbar-track {
          background: #f1f1f1; }
        .modal-con .modal-dialog .modal-content ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #373d45;
          border: 2px solid #555555; }
        .modal-con .modal-dialog .modal-content .modal-header {
          border-bottom-color: #bfbfbf;
          display: flex;
          justify-content: space-between; }
          .modal-con .modal-dialog .modal-content .modal-header .modal-title {
            font-size: 2rem;
            padding: 0;
            font-weight: 700;
            font-family: "Open-Sans", sans-serif;
            color: #373d45;
            margin: 0.2em; }
          .modal-con .modal-dialog .modal-content .modal-header .close {
            opacity: 1;
            font-size: 2em;
            font-weight: 900;
            padding: 0;
            position: relative;
            top: -10%;
            height: fit-content;
            border: none;
            background-color: transparent;
            outline: none;
            cursor: pointer; }
        .modal-con .modal-dialog .modal-content .modal-footer {
          display: flex;
          padding: 0.5em;
          border-top-color: #bfbfbf; }
          .modal-con .modal-dialog .modal-content .modal-footer .modal-btn {
            border-radius: 50px;
            border: none;
            margin: auto;
            font-family: "Open-Sans", sans-serif;
            width: 125px;
            font-size: 16px;
            background-color: #253138;
            color: #fff;
            line-height: 40px;
            cursor: pointer; } }
  @media (orientation: portrait) and (min-width: 600px) {
    .modal-con .modal-content {
      height: 85%; } }
  @media (orientation: portrait) and (min-width: 1020px) {
    .modal-con .modal-content {
      height: 90%;
      padding: 5px 55px; } }
