.tutorial.wrapper .title-wrapper {
  text-align: left; }
  .tutorial.wrapper .title-wrapper .title-text {
    margin-top: 24px;
    margin-bottom: 24px; }
    @media (min-width: 576px) {
      .tutorial.wrapper .title-wrapper .title-text {
        margin-bottom: 40px; } }
    @media (min-width: 1024px) {
      .tutorial.wrapper .title-wrapper .title-text {
        margin-bottom: 48px; } }

.tutorial.wrapper .steps-wrapper {
  position: relative; }
  .tutorial.wrapper .steps-wrapper .box-shadow {
    position: absolute;
    width: 100%;
    height: calc(100% - 2%);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    box-shadow: 0 0 3px rgba(186, 131, 255, 0.5);
    z-index: -1; }
    @media (min-width: 414px) {
      .tutorial.wrapper .steps-wrapper .box-shadow {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px; } }
    @media (min-width: 768px) {
      .tutorial.wrapper .steps-wrapper .box-shadow {
        border-top-left-radius: 13px;
        border-top-right-radius: 13px; } }
    @media (min-width: 1024px) {
      .tutorial.wrapper .steps-wrapper .box-shadow {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px; } }
    @media (min-width: 1920px) {
      .tutorial.wrapper .steps-wrapper .box-shadow {
        border-top-left-radius: 18px;
        border-top-right-radius: 18px; } }
